import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // You can use 'auto' for instant scrolling
  });
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const isBase64Image = (value: string) => {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  return base64regex.test(value);
};

export const convertBase64ImageToBlob = (value: string) => {
  // extract content type and base64 payload from base64 string
  const startingStringPosition = value.indexOf(';base64,');
  const type = value.substring(5, startingStringPosition);
  const base64String = value.substring(startingStringPosition + 8);

  // decode base64
  const imageContent = atob(base64String);

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  // fill the view, using the decoded base64
  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  // convert ArrayBuffer to Blob
  const blob = new Blob([buffer], { type });

  return blob as File;
};

export const convertToBase64String = (value: string) => {
  return `data:image/jpg;base64,${value}`;
};

export function maskPhoneNumber(
  phoneNumber: number | string | undefined | null
): string {
  if (!phoneNumber) {
    return ''; // Return an empty string if the phone number is undefined or null
  }

  // Ensure the phone number is a string
  const phoneStr = phoneNumber.toString();

  // If the phone number is shorter than 4 digits, return it as is
  if (phoneStr.length <= 4) {
    return phoneStr;
  }

  // Mask the digits and keep the last 4 digits visible
  const masked =
    phoneStr.slice(0, 3).replace(/\d/g, '*') +
    phoneStr.slice(3, -4).replace(/\d/g, '*') +
    phoneStr.slice(-4);

  return masked;
}