// features/roleSlice.ts
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { appAxios } from 'api/axios';

interface RoleState {
  loading: boolean;
  success: boolean;
  error: string | null;
  permissions: Array<{ id: string; name: string; userRoles: number }>;
}

const initialState: RoleState = {
  loading: false,
  success: false,
  error: null,
  permissions: [], // Initialize an empty permissions array
};

// Fetch permissions async thunk
export const fetchPermissions = createAsyncThunk(
  'roles/fetchPermissions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('/get-permissions');
      return response.data.data; // Return the permissions array
    } catch (error: any) {
      toast.error('Failed to fetch permissions');
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

// Async thunk to handle role creation
export const createRole = createAsyncThunk(
  'roles/createRole',
  async (
    roleData: { name: string; permissionIds: string[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await appAxios.post('create-role', roleData);
      toast.success('Role added successfully!');
      return response.data; // Ensure this includes the newly created role details
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Something went wrong');
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload; // Store the fetched permissions
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createRole.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.permissions.push(action.payload); // Add the new role to the permissions array
      })
      .addCase(createRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { resetState } = roleSlice.actions;
export default roleSlice.reducer;
