import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { appAxios } from 'api/axios';

// Define a type for the customer state
export interface Customer {
  id: string;
  fullName?: string | null;
  email?: string;
  contactAddress?: string | null;
  phone?: string; // Added optional field
  status?: string | null;
  createAt?: string; // Added optional field
  bvn?: string | null; // Added optional field
  bvnStatus?: 'Pending' | 'Completed' | null; // Added optional field
  bvnUrl?: string | null; // Added optional field
  nin?: string | null; // Added optional field
  ninStatus?: 'Pending' | 'Completed' | null; // Added optional field
  ninUrl?: string | null; // Added optional field
}

// Define the initial state using that type
interface CustomerState {
  customers: Customer[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CustomerState = {
  customers: [],
  status: 'idle',
  error: null,
};

// Async thunk to fetch customers
export const fetchCustomers = createAsyncThunk<Customer[], { page: number; pageSize: number; search?: string }>(
  'customers/fetchCustomers',
  async (params: { page: number; pageSize: number; search?: string }, { rejectWithValue }) => {
    try {
      const { page, pageSize, search } = params;
      const response = await appAxios.get<{ success: boolean; message: string; data: Customer[] }>(
        `/get-customers`,
        {
          params: { page, pageSize, search },
        }
      );

      // Normalize data before returning it to the state
      const normalizedData = response.data.data.map(customer => ({
        ...customer,
        fullName: customer.fullName ?? null,  // Normalize undefined to null
        email: customer.email ?? '',  // Set default empty string for email
        contactAddress: customer.contactAddress ?? null, // Normalize undefined to null
        phone: customer.phone ?? '',  // Set default empty string for phone
        status: customer.status ?? 'Pending',  // Default status to 'Pending'
        nin: customer.nin ?? null,  // Normalize undefined to null
        bvn: customer.bvn ?? null,  // Normalize undefined to null
      }));

      return normalizedData;  // Return normalized data to avoid type mismatch
    } catch (error: any) {
      console.error('Failed to fetch customers:', error);
      // Check if it's a 401 error (Unauthorized)
      if (error.response?.status === 401) {
        return rejectWithValue('Unauthorized: Please log in again');
      }
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch customers');
    }
  }
);

// Create the slice
const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.customers = action.payload;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string || 'Failed to fetch customers';
      });
  },
});

// Selector to get all customers
export const selectCustomers = (state: RootState) => state.customer.customers || [];

// Export the reducer to be included in the store
export default customerSlice.reducer;
