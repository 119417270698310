import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { appAxios } from 'api/axios';

// Define Provider and State Types
interface Provider {
  id: string;
  email: string;
  phoneNumber?: string | null;
  businessName?: string;
  status: string; 
  createAt: string;
}

interface ProviderState {
  providers: Provider[];
  totalProviders: number;
  pagination: {
    currentPage: number;
    pageSize: number;
  };
  loading: boolean;
  error: string | null;
}

// Initial State
const initialState: ProviderState = {
  providers: [],
  totalProviders: 0,
  pagination: {
    currentPage: 1,
    pageSize: 10,
  },
  loading: false,
  error: null,
};


// Define Async Thunk for Fetching Providers
export const fetchProviders = createAsyncThunk(
  'provider/fetchProviders',
  async ({ page, pageSize }: { page: number; pageSize: number }, thunkAPI) => {
    try {
      const response = await appAxios.get(`/get-vendors`, {
        params: {
          page,
          pageSize
        }
      });

      // Adjust according to your API response structure
      const data = response.data;

      return {
        providers: data.data, // Adjust this if your API response has a different key for the list of providers
        totalProviders: data.totalVendors, // Ensure this key matches the total count key from your API response
        currentPage: page,
        pageSize: pageSize,
      };
    } catch (error) {
      console.error('Error fetching providers:', error);
      return thunkAPI.rejectWithValue('Failed to fetch providers');
    }
  }
);


// Create Slice
const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProviders.fulfilled, (state, action: PayloadAction<{
        providers: Provider[];
        totalProviders: number;
        currentPage: number;
        pageSize: number;
      }>) => {
        state.providers = action.payload.providers;
        state.totalProviders = action.payload.totalProviders;
        state.pagination = {
          currentPage: action.payload.currentPage,
          pageSize: action.payload.pageSize,
        };
        state.loading = false;
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});


// Export Selectors
export const selectProviders = (state: RootState) => state.providers.providers;
export const selectProviderPagination = (state: RootState) => state.providers.pagination;


// Export Reducer
export default providerSlice.reducer;
