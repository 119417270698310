import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';

interface InterestRate {
  id: string;
  interestCostPartner: number;
  jompInterestPremium: number;
  interestAmount: number;
}

interface InterestRateState {
  interestRates: InterestRate[];
  loading: boolean;
  error: string | null;
  successMessage: string | null;
}

const initialState: InterestRateState = {
  interestRates: [],
  loading: false,
  error: null,
  successMessage: null,
};

// createAsyncThunk for fetching interest rates
export const getInterestRate = createAsyncThunk(
  'interestRate/getInterestRate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('/get-interateRate');
      return response.data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unexpected error occurred');
    }
  }
);

const interestRateSlice = createSlice({
  name: 'interestRate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInterestRate.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getInterestRate.fulfilled, (state, action: PayloadAction<{ data: InterestRate[] }>) => {
      state.loading = false;
      state.interestRates = action.payload.data;
    });
    builder.addCase(getInterestRate.rejected, (state, action) => {
      state.loading = false;
      // Explicitly cast the action.payload to string | undefined
      state.error = (action.payload as string) || 'Failed to fetch interest rates';
    });
  },
});

export default interestRateSlice.reducer;
