import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';

export interface UpdateInterestRateState {
  loading: boolean;
  error: string | null;
  successMessage: string | null;
}

const initialState: UpdateInterestRateState = {
  loading: false,
  error: null,
  successMessage: null,
};

export const updateInterestRate = createAsyncThunk(
  'interestRate/updateInterestRate',
  async ({ id, interestCostPartner, jompInterestPremium }: { id: string; interestCostPartner: number; jompInterestPremium: number; }, { rejectWithValue }) => {
    try {
      const response = await appAxios.put(`/update-interateRate`, {
        interestRateUpdateRequestId: id,
        interestCostPartner,
        jompInterestPremium
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unexpected error occurred');
    }
  }
);

const updateInterestRateSlice = createSlice({
  name: 'updateInterestRate',
  initialState,
  reducers: {
    resetMessages(state) {
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateInterestRate.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.successMessage = null;
    });
    builder.addCase(updateInterestRate.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.successMessage = action.payload.message; // Get success message from response
    });
    builder.addCase(updateInterestRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string; // Use error message from response
    });
  },
});

export const { resetMessages } = updateInterestRateSlice.actions;
export default updateInterestRateSlice.reducer;
