import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { RoutePaths } from "../route-paths";
import { ProtectedAdminRoute, PrivateAdminRoute } from "../utils";


const AdminLoginPage = lazy(() => import("../../pages/admin/auth/Login"));


const AdminServicePage = lazy(
  () => import('../../pages/admin/dashboard/services')
);

const AdminCustomersPage = lazy(
  () => import('../../pages/admin/dashboard/customers')
);

const AdminDashboardPage = lazy(
  () => import('./../../pages/admin/dashboard/index')
);

const AdminTransactions = lazy(
  () => import('../../pages/admin/dashboard/transactions')
);




const adminAuthRoutes: RouteObject[] = [


  {
    path: RoutePaths.ADMIN_LOGIN,
    element: (
      <ProtectedAdminRoute>
        <AdminLoginPage />
      </ProtectedAdminRoute>
    ),
  },

  
   {
    path: RoutePaths.ADMIN_DASHBOARD,
    element: (
      <ProtectedAdminRoute>
        <AdminDashboardPage />
      </ProtectedAdminRoute>
    ),
  }
  ,

   {
    path: RoutePaths.ADMIN_TRANSACTIONS,
    element: (
      <ProtectedAdminRoute>
        <AdminTransactions />
      </ProtectedAdminRoute>
    ),
  }
  ,
  {
    path: RoutePaths.ADMIN_SERVICES,
    element: (
      <PrivateAdminRoute>
        <AdminServicePage />
      </PrivateAdminRoute>
    ),
  },
  {
    path: RoutePaths.ADMIN_CUSTOMERS,
    element: (
      <PrivateAdminRoute>
        <AdminCustomersPage />
      </PrivateAdminRoute>
    ),
  },
];

export default adminAuthRoutes;
