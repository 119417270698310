import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';
import { RootState } from '../store';
import axios from 'axios';

// Define your state and service interface
export interface Service {
  serviceId: string;
  customerId: string;
  customerEmail: string;
  displayImage: string;
  customerName: string;
  serviceDescription: string;
  amount: number;
  status: string;
  createdDate: string;
}

export interface ServicesState {
  services: Service[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: ServicesState = {
  services: [],
  loading: false,
  error: null,
};

// Define your async thunk for fetching services
export const fetchServices = createAsyncThunk<
  Service[], 
  { page: number; pageSize: number }, 
  { rejectValue: string }
>(
  'services/fetchServices',
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        `/get-CustomerCreatedService`,
        { params: { page, pageSize } }
      );
      return response.data.data;
    } catch (error: any) {
      // Check if error is Axios error
      if (axios.isAxiosError(error)) {
        const message = error.response?.data?.message || error.message || 'Failed to fetch services';
        return rejectWithValue(message);
      } 
      return rejectWithValue('An unexpected error occurred');
    }
  }
);

// Define your slice
const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch services';
      });
  },
});

// Export the reducer
export default servicesSlice.reducer;

// Export selectors
export const selectServices = (state: RootState) => state.services.services;
export const selectLoading = (state: RootState) => state.services.loading;
export const selectError = (state: RootState) => state.services.error;
