import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { appAxios } from 'api/axios';

// Define the shape of user roles data
interface UserRole {
  userId: string;
  roleId: string;
  userName: string;
  roleName: string;
    createdAt: string;
    roleStatus: string;
}

interface UserRolesState {
  userRoles: UserRole[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: UserRolesState = {
  userRoles: [],
  loading: false,
  error: null,
};

// Thunk for fetching user roles
export const fetchUserRoles = createAsyncThunk(
  'userRoles/fetchUserRoles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('/get-user-roles'); // Replace with actual API URL
      return response.data.data; // Assuming the response contains the roles in 'data'
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch user roles'
      );
    }
  }
);

// Create the slice
const userRolesSlice = createSlice({
  name: 'userRoles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserRoles.fulfilled, (state, action) => {
        state.userRoles = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const selectUserRoles = (state: RootState) => state.userRoles.userRoles;

export default userRolesSlice.reducer;
