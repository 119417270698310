import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store'; // Adjust the path as needed
import { appAxios } from 'api/axios';

// Define the state interface
interface RevenueState {
  data: Array<{ month: string; revenue: number; order: number }>;
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: RevenueState = {
  data: [],
  loading: false,
  error: null,
};

// Async thunk to fetch revenue data
export const fetchRevenueData = createAsyncThunk<
  RevenueState['data'], // Return type of the fulfilled action
  void,                // Argument type passed to the thunk
  { rejectValue: string } // Type of the value returned by `rejectWithValue`
>(
  'revenue/fetchRevenueData',
  async (_, { rejectWithValue }) => {
    try {
      // Replace with your actual API call
      const response = await appAxios.get('/api/revenue');
      if (response.headers['content-type'] && response.headers['content-type'].includes('application/json')) {
        return response.data;
      } else {
        throw new Error('Unexpected content type');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        // Extract error message from Axios error
        return rejectWithValue(error.response.data.message || 'An error occurred');
      } else if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

// Revenue slice
export const revenueSlice = createSlice({
  name: 'revenue',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRevenueData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRevenueData.fulfilled, (state, action: PayloadAction<RevenueState['data']>) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchRevenueData.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.error = action.payload ?? 'Unknown error';
      });
  },
});

// Selectors
export const selectRevenueData = (state: RootState) => state.revenue.data;
export const selectRevenueLoading = (state: RootState) => state.revenue.loading;
export const selectRevenueError = (state: RootState) => state.revenue.error;

export default revenueSlice.reducer;
