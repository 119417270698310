import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';

interface PaymentType {
  name: string;
  description?: string;
  id: string;
  createAt: string;
  updateAt: string;
}

export interface EssentialService {
  id: string;
  name: string;
  servicePublicId: string;
  description: string | null;
  price: number;
  currencyCode: string;
  displayPicture: string;
  recurringPaymentOptionId: string | null;
  reccurringPaymentOption: string | null;
  paymentTypes: PaymentType[];
  vendorId: string;
  vendorName: string;
  vendorDescription: string | null;
  vendorPhoneNumber: string;
}

interface EssentialServicesState {
  services: EssentialService[];
  loading: boolean;
  error: string | null;
}

const initialState: EssentialServicesState = {
  services: [],
  loading: false,
  error: null,
};

// Fetch all essential services data (without searchTerm)
export const fetchEssentialServices = createAsyncThunk<
  EssentialService[], // Return type of the payload creator
  {serviceId: string, page: number; pageSize: number }, // Type of the parameter passed to the payload creator
  { rejectValue: string } // Type of the rejected value
>(
  'essentialServices/fetchEssentialServices',
  async ({serviceId, page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        `/get-essentialServices?serviceId=${serviceId}&page=${page}&pageSize=${pageSize}`
      );
      return response.data.data.essentialServices;
    } catch (error) {
      return rejectWithValue('Failed to fetch essential services');
    }
  }
);

const essentialServicesSlice = createSlice({
  name: 'essentialServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEssentialServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEssentialServices.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload;
      })
      .addCase(fetchEssentialServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string || 'Failed to fetch essential services';
      });
  },
});

export default essentialServicesSlice.reducer
