import { signOut } from '../store/slices/user';
import { store } from '../store/store';
import { API_URL } from './../functions/environmentVariables';
import axios from 'axios';

// Helper function to get token from local storage
const getTokenFromLocalStorage = (): string | null => {
  return localStorage.getItem('token');
};

export const appAxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  baseURL: API_URL,
  validateStatus: (status) => status <= 500,
});

appAxios.interceptors.request.use(
  (config) => {
    const appState = store.getState();
    let token = appState.user.token; // Try getting token from Redux store

    if (!token) {
      // If no token in Redux store, fallback to local storage
      token = getTokenFromLocalStorage();
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request error here
    return Promise.reject(error);
  }
);

appAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.code === 'ERR_NETWORK' && !originalConfig._retry) {
      console.log(err);
      originalConfig._retry = true;

      // Reload window so user is redirected to login
      store.dispatch(signOut());
      window.location.reload();

      return appAxios(originalConfig);
      return Promise.reject(err);
    }

    // if (originalConfig.url !== `${API_URL}/user-login` && err.response) {
    //   if (err.response.status === 401) {
    //     // Log user out on 401 Unauthorized error
    //     store.dispatch(signOut());

    //     // Reload window so user is redirected to login
    //     window.location.reload();

    //     return appAxios(originalConfig); // Retry original request
    //   }
    // }

    // return Promise.reject(err);
  }
);
