import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../store/store';
import { appAxios } from 'api/axios';

interface DocumentVerificationState {
  loading: boolean;
  error: string | null;
}

const initialState: DocumentVerificationState = {
  loading: false,
  error: null,
};

export const verifyDocument = createAsyncThunk(
  'documentVerification/verifyDocument',
  async ({
    providerId,
    documentId,
    status
  }: {
    providerId: string;
    documentId: string;
    status: string;
  }) => {
    try {
      const response = await appAxios.post(
        `/verifiy-document`,
        null,
        {
          params: {
            VendorId: providerId,
            DocumentId: documentId,
            Status: status,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        // Handle server errors
        throw new Error(error.response.data.message || 'Failed to verify document');
      } else {
        // Handle client errors or network issues
        throw new Error('Failed to verify document');
      }
    }
  }
);

const documentVerificationSlice = createSlice({
  name: 'documentVerification',
  initialState,
  reducers: {
    resetDocumentVerificationState: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyDocument.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(verifyDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to verify document';
      });
  },
});

export const { resetDocumentVerificationState } = documentVerificationSlice.actions;

export const selectDocumentVerificationLoading = (state: RootState) =>
  state.documentVerification.loading;

export const selectDocumentVerificationError = (state: RootState) =>
  state.documentVerification.error;

export default documentVerificationSlice.reducer;
