import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';

// Define the state structure for interest rate setup
interface InterestRateState {
  loading: boolean;
  success: boolean | null;
  message: string | null;
  error: string | null;
}

const initialState: InterestRateState = {
  loading: false,
  success: null,
  message: null, // Set to null for type consistency
  error: null,
};

// Async thunk to set interest rates
export const setInterestRate = createAsyncThunk<
  { message: string }, // Return type of fulfilled action (response)
  { settingName: string; providerInterest: number; jompStart: number }, // Parameters of the function
  { rejectValue: { message: string } } // Type of error object returned by rejectWithValue
>(
  'interestRate/setInterestRate',
  async ({ settingName, providerInterest, jompStart }, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('/interest-setup', {
        settingName,  // Include settingName in the request body
        providerInterest,
        jompStart,
      });
      return response.data; // Ensure response data contains a message
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: 'Failed to set interest rates' });
    }
  }
);

const interestRateSlice = createSlice({
  name: 'interestRate',
  initialState,
  reducers: {
    resetInterestRateState: (state) => {
      state.loading = false;
      state.success = null;
      state.message = null; // Set to null for type consistency
      state.error = null;
    },
    resetSuccess: (state) => {
      state.success = null;
    },
    resetError: (state) => {
      state.error = null; // Reset the error
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setInterestRate.pending, (state) => {
        state.loading = true;
        state.success = null;
        state.error = null;
      })
      .addCase(setInterestRate.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload.message; // Ensure payload has a message property
      })
      .addCase(setInterestRate.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload?.message || 'An error occurred'; // Handle error appropriately
      });
  },
});

export const { resetInterestRateState, resetSuccess, resetError } = interestRateSlice.actions;

export default interestRateSlice.reducer;
