import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { appAxios } from 'api/axios';

interface RepaymentDetail {
  repaymentDetailId: string;
  loanType: string;
  loanAmount: number;
  dueDate: string;
  openingBalance: number;
  interest: number;
  monthlyInstallment: number;
  closingPrincipal: number;
  status: string;
}

interface RepaymentState {
  repaymentDetails: RepaymentDetail[];
  loading: boolean;
  error: string | null;
}

const initialState: RepaymentState = {
  repaymentDetails: [],
  loading: false,
  error: null,
};

// Thunk to fetch repayment data
export const fetchRepaymentDetails = createAsyncThunk(
  'repayment/fetchRepaymentDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('/loan-current-repayment');
      return response.data.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch repayment details');
    }
  }
);

const repaymentSlice = createSlice({
  name: 'repayment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRepaymentDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRepaymentDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.repaymentDetails = action.payload;
      })
      .addCase(fetchRepaymentDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const selectRepaymentDetails = (state: RootState) => state.repayment;
export default repaymentSlice.reducer;
