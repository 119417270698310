// src/store/slices/statisticsSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { appAxios } from 'api/axios';

interface StatisticsState {
  totalRevenue: number;
  totalTransactions: number;
  totalInterests: number;
  totalServices: number;
  totalCustomers: number;
  totalProviders: number;
  customersPercentageChange: number;
  vendorsPercentageChange: number;
  transactionsPercentageChange: number;
  servicesPercentageChange: number;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: StatisticsState = {
  totalRevenue: 0,
  totalTransactions: 0,
  totalInterests: 0,
  totalServices: 0,
  totalCustomers: 0,
  totalProviders: 0,
  customersPercentageChange: 0,
  vendorsPercentageChange: 0,
  transactionsPercentageChange: 0,
  servicesPercentageChange: 0,
  status: 'idle',
  error: null,
};


// Async thunk to fetch statistics data
export const fetchStatistics = createAsyncThunk(
  'statistics/fetchStatistics',
  async () => {
    const response = await appAxios.get(`/get-dashboardDetails`);
    const { data } = response.data; // Extract the 'data' array from the response

    // Initialize statistics object
    const statistics = {
      totalRevenue: 0, // assuming 'Revenue' type exists in the actual API data
      totalTransactions: 0, // assuming 'Transactions' type exists in the actual API data
      totalInterests: 0, // assuming 'Interests' type exists in the actual API data
      totalServices: 0, // assuming 'Services' type exists in the actual API data
      totalCustomers: 0,
      totalProviders: 0,
 customersPercentageChange: 0,
  vendorsPercentageChange: 0,
  transactionsPercentageChange: 0,
  servicesPercentageChange: 0,    };

    // Map the response data to the statistics object
    data.forEach(
      (item: { type: string; count: number; percentageChange: 0 }) => {
        switch (item.type) {
          case 'Revenue':
            statistics.totalRevenue = item.count;

            break;
          case 'Transactions':
            statistics.totalTransactions = item.count;
            statistics.transactionsPercentageChange = item.percentageChange;

            break;
          case 'Interests':
            statistics.totalInterests = item.count;

            break;
          case 'Services':
            statistics.totalServices = item.count;
            statistics.servicesPercentageChange = item.percentageChange;

            break;
          case 'Customers':
            statistics.totalCustomers = item.count;
            statistics.customersPercentageChange = item.percentageChange;

            break;
          case 'Vendors':
            statistics.totalProviders = item.count;
            statistics.vendorsPercentageChange= item.percentageChange;

            break;
          default:
            break;
        }
      }
    );

    return statistics;
  }
);

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatistics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStatistics.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.totalRevenue = action.payload.totalRevenue;
        state.totalTransactions = action.payload.totalTransactions;
        state.totalInterests = action.payload.totalInterests;
        state.totalServices = action.payload.totalServices;
        state.totalCustomers = action.payload.totalCustomers;
        state.totalProviders = action.payload.totalProviders;
        state.customersPercentageChange = action.payload.customersPercentageChange;
                state.vendorsPercentageChange = action.payload.vendorsPercentageChange;
        state.servicesPercentageChange = action.payload.servicesPercentageChange;
        state.transactionsPercentageChange = action.payload.transactionsPercentageChange;

      })
      .addCase(fetchStatistics.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch statistics';
      });
  },
});

// Export selectors
export const selectTotalRevenue = (state: RootState) =>
  state.statistics.totalRevenue;
export const selectTotalTransactions = (state: RootState) =>
  state.statistics.totalTransactions;
export const selectTotalInterests = (state: RootState) =>
  state.statistics.totalInterests;
export const selectTotalServices = (state: RootState) =>
  state.statistics.totalServices;
export const selectTotalCustomers = (state: RootState) =>
  state.statistics.totalCustomers;
export const selectTotalProviders = (state: RootState) =>
  state.statistics.totalProviders;
export const selectStatisticsStatus = (state: RootState) =>
  state.statistics.status;
export const customersPercentageChange= (state: RootState) =>
  state.statistics.customersPercentageChange;
export const servicesPercentageChange= (state: RootState) =>
  state.statistics.servicesPercentageChange;
export const transactionsPercentageChange= (state: RootState) =>
  state.statistics.transactionsPercentageChange;
export const vendorsPercentageChange= (state: RootState) =>
  state.statistics.vendorsPercentageChange;
export const selectStatisticsError = (state: RootState) =>
  state.statistics.error;

export default statisticsSlice.reducer;
