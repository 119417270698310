import { toast } from 'react-toastify';

type Type = 'success' | 'info' | 'warning' | 'error';
type Message = string;

export const sendFeedback = (message: Message, type: Type = 'info') => {
  toast[type](message, {
    toastId: 'toastId', // Ensure only one toast with this ID appears at a time
  });
};

// Handles specific error messages, especially for network issues or server responses
export const sendCatchFeedback = (error: any) => {
  if (error.response?.data?.message) {
    sendFeedback(error.response.data.message, 'error');
  } else if (error.response?.status === 401) {
    sendFeedback('Unauthorized access. Please check your credentials.', 'error');
  } else if (error.response?.status === 400) {
    sendFeedback('Invalid email or password. Please try again.', 'error');
  } else {
    sendFeedback('Request unsuccessful. Please try again later.', 'error');
  }
};
