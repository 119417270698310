import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';

// Define the type for the interest rate data
interface InterestRate {
  id: string;
  settingsName: string;
  interestCostPartner: number;
  jompInterestPremium: number;
  interestAmount: number;
}

// Define the initial state type
interface InterestRateState {
  data: InterestRate[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: InterestRateState = {
  data: [],
  loading: false,
  error: null,
};

// Thunk for fetching interest rate setup
export const fetchInterestRates = createAsyncThunk(
  'interestRates/fetchInterestRates',
  async () => {
    const response = await appAxios.get('/get-interest-setup');
    return response.data.data; // Returning only the `data` array
  }
);

// Create the slice
const interestRateSlice = createSlice({
  name: 'interestRates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInterestRates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInterestRates.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchInterestRates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default interestRateSlice.reducer;
