import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';

// Define the state structure for admin creation
interface AdminState {
  loading: boolean;
  success: boolean | null;
  message: string | null;
  error: string | null;
}

const initialState: AdminState = {
  loading: false,
  success: null,
  message: '',
  error: null,
};

// Async thunk to create an admin
export const createAdmin = createAsyncThunk<
  { message: string }, // Return type of fulfilled action (response)
  { email: string; role: string }, // Parameters of the function (email and role)
  { rejectValue: { message: string } } // Type of error object returned by rejectWithValue
>(
  'admin/createAdmin',
  async ({ email, role }, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('/create-adminuser', {
        email,
        role, // Pass `role` here as expected in the request body
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: 'Failed to create admin' });
    }
  }
);

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetAdminState: (state) => {
      state.loading = false;
      state.success = null;
      state.message = '';
      state.error = null;
    },
    resetSuccess: (state) => {
      state.success = null;
    },
      resetError: (state) => {
      state.error = null; // Add this action to reset the error
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAdmin.pending, (state) => {
        state.loading = true;
        state.success = null;
        state.error = null;
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload.message;
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload?.message || 'An error occurred';
      });
  },
});

export const { resetAdminState, resetSuccess, resetError } = adminSlice.actions;

export default adminSlice.reducer;
