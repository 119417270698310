import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { appAxios } from 'api/axios';

// Define Customer and State Types

export interface Customer {
  id: string;
  email: string;
  fullName: string | null;
  phoneNumber: string | null;
  contactAddress: string;
  profileImage: string;
  bvn: number | null;
  bvnStatus: string;
  niN: number;
  ninStatus: string;
  status: string;
  avatarUrl: string;
  accountNumber: number | null;
  bankName: string | null;
  accountHolder: string;
}

export interface CustomerDetailsState {
  customer: Customer | null; // Changed 'provider' to 'customer' for consistency
  loading: boolean;
  error: string | null;
}

// Initial State
export const initialState: CustomerDetailsState = {
  customer: null, // Changed 'provider' to 'customer'
  loading: false,
  error: null,
};

// Define Async Thunk for Fetching Customer Details
export const fetchCustomerDetails = createAsyncThunk(
  'customer/fetchCustomerDetails',
  async (customerId: string, thunkAPI) => {
    try {
      const response = await appAxios.get(
        `/get-customer?customerId=${customerId}`
      );

      const data = response.data.data; // Adjust according to your API response structure
      return data as Customer; // Ensure the returned data matches the Customer type
    } catch (error) {
      console.error('Error fetching customer details:', error);
      return thunkAPI.rejectWithValue('Failed to fetch customer details'); // Updated the error message
    }
  }
);

// Create Slice
const customerDetailsSlice = createSlice({
  name: 'customerDetails',
  initialState,
  reducers: {
    resetCustomerDetailsState(state) {
      // Changed 'resetProviderDetailsState' to 'resetCustomerDetailsState'
      state.customer = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchCustomerDetails.fulfilled,
        (state, action: PayloadAction<Customer>) => {
          state.customer = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchCustomerDetails.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

// Export Actions
export const { resetCustomerDetailsState } = customerDetailsSlice.actions;

// Export Selectors
export const selectCustomerDetails = (state: RootState) =>
  state.customerDetails.customer;
export const selectCustomerDetailsLoading = (state: RootState) =>
  state.customerDetails.loading;
export const selectCustomerDetailsError = (state: RootState) =>
  state.customerDetails.error;

// Export Reducer
export default customerDetailsSlice.reducer;
