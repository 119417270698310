import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ServiceRequest {
  id: number;
  dateRequested: string;
  providerName: string;
  service: string;
  amount: string;
  paymentType: string;
  paymentDuration: string;
  status: string;
}

interface ServiceRequestsState {
  requests: ServiceRequest[];
}

const initialState: ServiceRequestsState = {
  requests: [],
};

const serviceRequestsSlice = createSlice({
  name: 'serviceRequests',
  initialState,
  reducers: {
    setServiceRequests: (state, action: PayloadAction<ServiceRequest[]>) => {
      state.requests = action.payload;
    },
  },
});


export const { setServiceRequests } = serviceRequestsSlice.actions;
export default serviceRequestsSlice.reducer;
