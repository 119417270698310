import { Navigate } from 'react-router-dom';
import { sendFeedback } from '../functions/feedback';
import { RoutePaths } from './route-paths';
import { decodeToken } from 'react-jwt';
import { TokenType } from '../types/user';
import { useAppDispatch } from 'store/hooks';
import { setClientId, setClientType } from 'store/slices/user';
import { useAppSelector } from 'store/hooks';
import { getSessionDetails, getTokenDetails } from '../functions/userSession';

export const PrivateAdminRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const dispatch = useAppDispatch();
  const token = getTokenDetails(); // Fetch token from local storage
  const clientType = useAppSelector((state) => state.user.clientType);

  if (!token) {
    sendFeedback('Login to continue', 'error');
    return <Navigate to={RoutePaths.ADMIN_LOGIN} replace />;
  }
  try {
    const decodedToken = decodeToken<TokenType>(token);

    if (decodedToken) {
      dispatch(setClientId(decodedToken.clientId));
      dispatch(setClientType('management-client'));
    }

    if (!decodedToken || decodedToken.clientId !== 'management-client') {
      return <Navigate to={RoutePaths.ADMIN_LOGIN} replace />;
    }
  } catch (error) {
    return <Navigate to={RoutePaths.ADMIN_LOGIN} replace />;
  }

  // If everything is okay, render the protected component
  return children;
};

export const ProtectedAdminRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const currentUser = getSessionDetails();
  const token = getTokenDetails();
  if (token) {
    sendFeedback('You are already logged in');
    return <Navigate to={RoutePaths.ADMIN_DASHBOARD} replace />;
  }

  return children;
};

// export const ProtectedAdminRoute = ({
//   children,
// }: {
//   children: React.ReactElement;
// }) => {
//   return children;
// };
