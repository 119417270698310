import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user';
import customersReducer from './slices/customerSlice';
import providersReducer from './slices/providerSlice';
import statisticsReducer from './slices/statisticsSlice';
import revenueReducer from './slices/revenueSlice';
import servicerequestsReducer from './slices/servicerequestsSlice';
import providerDetailsReducer from './slices/providerDetailsSlice';
import customerDetailsReducer from './slices/customerDetailsSlice';
import documentVerificationReducer from './slices/documentVerificationSlice';
import providerVerificationReducer from './slices/verifyVendorSlice';
import authReducer from './slices/authSlice';
import essentialServicesReducer from './slices/essentialServicesSlice';
import servicesReducer from './slices/customerServicesSlice';
import transactionReducer from './slices/transactionsSlice';
import getInterestRateReducer from './slices/getInterestRateSlice';
import updateInterestRateReducer from './slices/updateInterestRateSlice';
import createAdminReducer from './slices/createadminSlice';
import roleReducer from './slices/createRoleSlice'; 
import { rolesReducer } from './slices/getRolesSlice';
import userRolesReducer from './slices/getAdminUsersSlices'; 
import interestRateReducer from './slices/getInterestsSlice'; 
import interestSetupReducer from './slices/setupRateSlice'; 
import loanReducer from './slices/loanSlice'; 
import repaymentReducer from './slices/repaymentSlice';




 

// Configure the Redux store
export const store = configureStore({
  reducer: {
    user: userReducer,
    statistics: statisticsReducer,
    revenue: revenueReducer,
    providers: providersReducer,
    customer: customersReducer,
    servicerequests: servicerequestsReducer,
    providerDetails: providerDetailsReducer,
    customerDetails: customerDetailsReducer,
    documentVerification: documentVerificationReducer,
    providerVerification: providerVerificationReducer,
    auth: authReducer,
    essentialServices: essentialServicesReducer,
    services: servicesReducer,
    transactions: transactionReducer,
    getInterestRate: getInterestRateReducer,
    updateInterestRate: updateInterestRateReducer,
    createAdmin: createAdminReducer, 
    createRole: roleReducer,
    getRoles: rolesReducer,
    userRoles: userRolesReducer,
    interestRates: interestRateReducer,
    interestSetup: interestSetupReducer,
    loan: loanReducer,
    repayment: repaymentReducer,
  },
  // Uncomment this if you have middleware to apply
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(yourCustomMiddleware),
});

// Define RootState and AppDispatch types for type safety
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
