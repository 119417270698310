import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';

interface LoanData {
  type: string;
  loanAmount: number;
  totalRepayment: number | null;
  count: number;
  previousMonthCount: number;
  percentageChange: string;
}

interface LoanState {
  loanDetails: LoanData[];
  loading: boolean;
  error: string | null;
}

const initialState: LoanState = {
  loanDetails: [],
  loading: false,
  error: null,
};

// Thunk to fetch loan details
export const fetchLoanDashboardDetail = createAsyncThunk(
  'loan/fetchLoanDashboardDetail',
  async (_, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('/loan-dashboard-detail');
      return response.data.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch loan dashboard details');
    }
  }
);

const loanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanDashboardDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLoanDashboardDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.loanDetails = action.payload;
      })
      .addCase(fetchLoanDashboardDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default loanSlice.reducer;
