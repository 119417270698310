import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from '../../types/user';
import {
  removeSessionDetails,
  removeTokenDetails,
  storeSessionDetails,
  storeTokenDetails,
} from './../../functions/userSession';

// Define the initial state using that type
const initialState: {
  admin: UserType | null;
  token: string | null;
  clientId: string | null;
  otpData: { otp: string | null; email: string; pass: string };
  userType: 'admin' | null;
  clientType: 'management-client' | null;
} = {
  admin: null,
  token: null,
  clientId: null,
  otpData: { otp: null, email: '', pass: '' },
  userType: null,
  clientType: null,
};

// Actual Slice
export const userSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    updateAdmin(state, action: PayloadAction<{ admin: UserType }>) {
      state.admin = action.payload.admin;
      storeSessionDetails(action.payload.admin);
    },
    updateToken(state, action: PayloadAction<{ token: string }>) {
      state.token = action.payload.token;
      storeTokenDetails(action.payload.token);
    },

    signOut(state) {
      state.admin = null;
      state.token = null;
      state.clientId = null;
      state.clientType = null;
      removeSessionDetails();
      removeTokenDetails();
    },
    setOtpData(
      state,
      action: PayloadAction<{ otp: string; email: string; pass: string }>
    ) {
      state.otpData = action.payload;
    },
    setUserType(state, action: PayloadAction<'admin'>) {
      state.userType = action.payload;
    },
    setClientId(state, action: PayloadAction<string>) {
      state.clientId = action.payload;
    },
    setClientType(
      state,
      action: PayloadAction<'management-client'>
    ) {
      state.clientType = action.payload;
    },
  },
});

export const {
  updateAdmin,
  signOut,
  updateToken,
  setOtpData,
  setUserType,
  setClientId,
  setClientType,
} = userSlice.actions;

export default userSlice.reducer;
