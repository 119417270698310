import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { appAxios } from 'api/axios';

// Define Provider Details and State Types
export interface UserVerificationResponse {
  id: string;
  businessType: string;
  documentType: string;
  verificationStatus: string;
  documentUrl: string;
  registrationNumber: string | null;
}

export interface Provider {
  id: string;
  email: string;
  fullName: string | null;
  phoneNumber: string;
  businessName: string;
  status: string;
  documentSize: '1mb'; // Adjusted to be dynamic
  accountNumber: '3094690292';
  bankName: 'Anwara Callistus Nnachi';
  avatarUrl: string; 
  contactAddress: string;
  businessType: 'Limited Liability Company';
  documentUrl: string;
  documentName: string;
  accountHolder: string;
  accountName: string | null;
  userVerificationResponse: UserVerificationResponse[];
  accountType: string | null;
}

export interface ProviderDetailsState {
  provider: Provider | null;
  loading: boolean;
  error: string | null;
}

// Initial State
export const initialState: ProviderDetailsState = {
  provider: null,
  loading: false,
  error: null,
};


// Define Async Thunk for Fetching Provider Details
export const fetchProviderDetails = createAsyncThunk(
  'provider/fetchProviderDetails',
  async (providerId: string, thunkAPI) => {
    try {
      const response = await appAxios.get(`/get-singleVendors/${providerId}`);
      return response.data.data; // Ensure this matches your API response
    } catch (error) {
      console.error('Error fetching provider details:', error);
      return thunkAPI.rejectWithValue('Failed to fetch provider details');
    }
  }
);

// Create Slice
const providerDetailsSlice = createSlice({
  name: 'providerDetails',
  initialState,
  reducers: {
    resetProviderDetailsState(state) {
      state.provider = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviderDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProviderDetails.fulfilled, (state, action: PayloadAction<Provider>) => {
        state.provider = action.payload;
        state.loading = false;
      })
      .addCase(fetchProviderDetails.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

// Export Actions
export const { resetProviderDetailsState } = providerDetailsSlice.actions;

// Export Selectors
export const selectProviderDetails = (state: RootState) => state.providerDetails.provider;
export const selectProviderDetailsLoading = (state: RootState) => state.providerDetails.loading;
export const selectProviderDetailsError = (state: RootState) => state.providerDetails.error;

// Export Reducer
export default providerDetailsSlice.reducer;
