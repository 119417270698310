import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { RoutePaths } from "../route-paths";
import { PrivateAdminRoute } from '../utils';
const DashboardLayout = lazy(() => import('../../layouts/dashboard-layout'));
const AdminServicesPage = lazy(
  () => import('../../pages/admin/dashboard/services')
);
const AdminSettingsPage = lazy(
  () => import('../../pages/admin/dashboard/settings')
);

const AdminDashboardPage = lazy(() => import('../../pages/admin/dashboard'));
const AdminCustomersPage = lazy(
  () => import('../../pages/admin/dashboard/customers')
);
const AdminProvidersPage = lazy(
  () => import('../../pages/admin/dashboard/providers')
);
const CustomerDetailsPage = lazy(
  () => import('../../pages/admin/dashboard/customerDetails')
);
const CustomerServiceDetailsPage = lazy(
  () => import('../../pages/admin/dashboard/services/CustomerServiceDetails')
);

const CustomerAcceptedServiceDetailsPage = lazy(
  () =>
    import(
      '../../pages/admin/dashboard/services/CustomerAcceptedServiceDetails'
    )
);

const AdminProviderDetailsPage = lazy(
  () => import('../../pages/admin/dashboard/providerDetails')
);
const ServiceRequestTable = lazy(
  () => import('../../pages/admin/dashboard/serviceRequests')
);

const EssentialsDetailsPage = lazy(
  () => import('../../pages/admin/dashboard/essentialsDetails')
);

const AdminTransactions = lazy(
  () => import('../../pages/admin/dashboard/transactions')
);

const InterestRateSettings = lazy(
  () => import('../../pages/admin/dashboard/interestRates')
);

const RolesAndPermissions = lazy(
  () => import('../../pages/admin/dashboard/rolesAndPermissions')
);

const AdminLoansPage = lazy(() => import('../../pages/admin/dashboard/loans'));
const RateSetup = lazy(
  () => import('../../pages/admin/dashboard/interestSetup')
);

const AdminLoansRepaymentPage = lazy(
  () => import('../../pages/admin/dashboard/loanRepayment/loanRepay')
);
const AdminLoansRepaymentDetailsPage = lazy(
  () => import('../../pages/admin/dashboard/loanRepayment/loanDetails')
);

const AdminSavingsPage = lazy(
  () => import('../../pages/admin/dashboard/savings')
);

const AdminSavingsTransactionsPage = lazy(
  () => import('../../pages/admin/dashboard/savings/SavingsTransactions')
);
const AdminSavingsTransactionsDetailsPage = lazy(
  () => import('../../pages/admin/dashboard/savings/SavingsTransactionDetails')
);

// const LoanRepayment = lazy(
//   () => import('../../pages/admin/dashboard/loan-repayment')
// );
// const LoanRepaymentDetails = lazy(
//   () => import('../../pages/admin/dashboard/loan-repayment-details')
// );

const AdminUsers = lazy(() => import('../../pages/admin/dashboard/adminUsers'));

const adminDashboardRoutes: RouteObject[] = [
  {
    path: RoutePaths.ADMIN_DASHBOARD,
    element: (
      <PrivateAdminRoute>
        <DashboardLayout />
      </PrivateAdminRoute>
    ),
    children: [
      {
        path: RoutePaths.ADMIN_DASHBOARD,
        element: (
          <PrivateAdminRoute>
            <AdminDashboardPage />
          </PrivateAdminRoute>
        ),
        index: true,
      },
      {
        path: RoutePaths.ADMIN_TRANSACTIONS,
        element: (
          <PrivateAdminRoute>
            <AdminTransactions />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_SETTINGS,
        element: (
          <PrivateAdminRoute>
            <AdminSettingsPage />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_CUSTOMERS,
        element: (
          <PrivateAdminRoute>
            <AdminCustomersPage />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_LOANS,
        element: (
          <PrivateAdminRoute>
            <AdminLoansPage />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_SAVINGS,
        element: (
          <PrivateAdminRoute>
            <AdminSavingsPage />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_SAVINGS_TRANSACTIONS,
        element: (
          <PrivateAdminRoute>
            <AdminSavingsTransactionsPage />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_SAVINGS_TRANSACTIONS_DETAILS,
        element: (
          <PrivateAdminRoute>
            <AdminSavingsTransactionsDetailsPage />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_PROVIDERS,
        element: (
          <PrivateAdminRoute>
            <AdminProvidersPage />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_CUSTOMER_DETAILS,
        element: (
          <PrivateAdminRoute>
            <CustomerDetailsPage />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_CUSTOMER_SERVICE_DETAILS,
        element: (
          <PrivateAdminRoute>
            <CustomerServiceDetailsPage />
          </PrivateAdminRoute>
        ),
      },

      {
        path: RoutePaths.ADMIN_CUSTOMER_ACCEPTED_SERVICE_DETAILS,
        element: (
          <PrivateAdminRoute>
            <CustomerAcceptedServiceDetailsPage />
          </PrivateAdminRoute>
        ),
      },

      {
        path: RoutePaths.ADMIN_SERVICES,
        element: (
          <PrivateAdminRoute>
            <AdminServicesPage />
          </PrivateAdminRoute>
        ),
      },

      {
        path: RoutePaths.ADMIN_PROVIDER_DETAILS,
        element: (
          <PrivateAdminRoute>
            <AdminProviderDetailsPage />
          </PrivateAdminRoute>
        ),
      },

      {
        path: RoutePaths.ADMIN_CUSTOMER_SERVICE_REQUESTS,
        element: (
          <PrivateAdminRoute>
            <ServiceRequestTable />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_ESSENTIALS_DETAILS,
        element: (
          <PrivateAdminRoute>
            <EssentialsDetailsPage />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_INTEREST_RATES,
        element: (
          <PrivateAdminRoute>
            <InterestRateSettings />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ROLES_AND_PERMISSION,
        element: (
          <PrivateAdminRoute>
            <RolesAndPermissions />
          </PrivateAdminRoute>
        ),
      },

      {
        path: RoutePaths.INTERST_SETUP,
        element: (
          <PrivateAdminRoute>
            <RateSetup />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_USERS,
        element: (
          <PrivateAdminRoute>
            <AdminUsers />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_LOANS_REPAYMENT,
        element: (
          <PrivateAdminRoute>
            <AdminLoansRepaymentPage />
          </PrivateAdminRoute>
        ),
      },
      {
        path: RoutePaths.ADMIN_VIEW_LOANS_REPAYMENT,
        element: (
          <PrivateAdminRoute>
            <AdminLoansRepaymentDetailsPage />
          </PrivateAdminRoute>
        ),
      },
    ],
  },
];

export default adminDashboardRoutes;
