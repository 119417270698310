import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../store/store';
import { appAxios } from 'api/axios';

interface ProviderVerificationState {
  loading: boolean;
  error: string | null;
  providerData: {
    id: string | null;
    userId: string | null;
    email: string | null;
    contactAddress: string | null;
    fullName: string | null;
    phoneNumber: string | null;
    status: string | null;
    businessName: string | null;
    profileImage: string | null;
  } | null;
}

const initialState: ProviderVerificationState = {
  loading: false,
  error: null,
  providerData: {
    id: null,
    userId: null,
    email: null,
    contactAddress: null,
    fullName: null,
    phoneNumber: null,
    status: null,
    businessName: null,
    profileImage: null,
  },
};

export const verifyProvider = createAsyncThunk(
  'providerVerification/verifyProvider',
  async ({
    VendorId,
    Status,
  }: {
    VendorId: string;
    Status: string;
  }) => {
    try {
      const response = await appAxios.post(
        `/activate-vendor`,
        null,
        {
          params: {
            VendorId,
            Status,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        throw new Error(error.response.data.message || 'Failed to verify provider');
      } else {
        throw new Error('Failed to verify provider');
      }
    }
  }
);

const providerVerificationSlice = createSlice({
  name: 'providerVerification',
  initialState,
  reducers: {
    resetProviderVerificationState: (state) => {
      state.loading = false;
      state.error = null;
      state.providerData = initialState.providerData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyProvider.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyProvider.fulfilled, (state, action) => {
        state.loading = false;
        state.providerData = action.payload.data;
      })
      .addCase(verifyProvider.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to verify provider';
      });
  },
});

export const { resetProviderVerificationState } =
  providerVerificationSlice.actions;

export const selectProviderVerificationLoading = (state: RootState) =>
  state.providerVerification.loading;

export const selectProviderVerificationError = (state: RootState) =>
  state.providerVerification.error;

export const selectProviderVerificationData = (state: RootState) =>
  state.providerVerification.providerData;

export default providerVerificationSlice.reducer;
