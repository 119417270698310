import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';

// Define the structure of the state
interface TransactionState {
  transactions: any[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: TransactionState = {
  transactions: [],
  loading: false,
  error: null,
};


// Thunk to fetch transactions with dynamic page and pageSize
export const fetchTransactions = createAsyncThunk(
  'transactions/fetchTransactions',
  async ({ page, pageSize }: { page: number; pageSize: number }, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        `/transactions`,
        {
          params: { page, pageSize }
        }
      );
      return response.data.data.data; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || 'Something went wrong');
    }
  }
);

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default transactionSlice.reducer;
