// src/features/rolesSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';
export interface Role {
  id: string;
  name: string;
  userRoles: number[];
}

export interface RolesState {
  roles: Role[];
  loading: boolean;
  error: string | null;
}

export const initialState: RolesState = {
  roles: [],
  loading: false,
  error: null,
};

export const getRoles = createAsyncThunk('roles/fetchRoles', async () => {
  const response = await appAxios.get('/get-roles'); // Adjust the URL as necessary
  return response.data.data; // Return the roles data
});

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch roles';
      });
  },
});

export const rolesReducer = rolesSlice.reducer;
